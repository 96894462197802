import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { graphql, Link, navigate } from "gatsby"
import { useLocation } from '@reach/router'
import toast from "react-hot-toast"
import Modal from "react-bootstrap/Modal"
import PhoneInput from 'react-phone-input-2'
import SimpleReactValidator from "simple-react-validator"

import 'react-phone-input-2/lib/style.css'

import USER_API from "../../../api/user"
import API, { initHeaders } from "../../../api/doctors"
import { format24To12HoursTime, getCookie, setCookie, getLowerCaseNoSpace, acceptOnlyNumbers } from "../../../service/helper"
import useScript from '../../../service/useScript';

import APIButton from "../../addon/button/apiButton"
import LinkButton from "../../addon/button/linkButton"
import MobileVerifyViaOTP from "../inputElements/mobileVerifyViaOtp"
import UseCustomValidator from "../../../service/simpleValidator"

const BookAppointmentModal = (props) => {

    const { showModal, closeModal, userInfo } = props
    const location = useLocation()
    const { customMobileValidator } = UseCustomValidator()
    const [, forceUpdateForRegister] = useState();
    let ogForm = { Firstname: '', Lastname: '', Email: '', Mobile: '', MobileCode: '', Country: '', MobileExt: '' }
    const [form, setForm] = useState(ogForm)
    const [otp, setOTP] = useState('');
    const [countryData, setCountryData] = useState([])
    const [payload, setPayload] = useState(userInfo);
    const [verifyOpen, setVerifyOpen] = useState(null);
    const [otpLoading, setOTPLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    useScript("/assets/js/login-register.js");

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))
    const mobileValidator = customMobileValidator
    const [otpValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" >The OTP field is required.</p>}
    )))

    const onVerify = () => {
        const mobileResult = mobileValidator.current.allValid()
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(1)
            setVerifyOpen(false);
        }else{
            onResendOTP()
        }
    }

    const onResendOTP = () => {
        setOTPLoading(true)
        USER_API.sendOTP({ mobileNo : form.Mobile, "otpFor": "quick_register" })
        .then(response => {
            setVerifyOpen(true);
            toast.success("OTP sucessfully sent on mobile no.")
            setOTPLoading(false)
        })
        .catch(err => {
            console.log(err)
            toast.error("Something went wrong. Please try again later")
            setVerifyOpen(false);
            setOTPLoading(false)
        })
    }

    const validateOTP = () => {
        return USER_API.verifyOTP(otp)
        .then(res => {
            return res
        })
        .catch(err => {
            console.log(err)
            return false
        })
    }

    const continueRegister = async () => {
        const formResult = formValidator.current.allValid()
        const mobileResult = mobileValidator.current.allValid()
        const otpResult = otpValidator.current.allValid()
        if (formResult === false) {
            formValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if (otpResult === false || otp.length === 0) {
            otpValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(formResult && mobileResult && otp.length === 0) {
            toast.error("Please verify OTP before proceeding")
        }
        if(formResult && mobileResult && otpResult && otp.length !== 0) {
            setBtnLoading(true)
            const otpVerify = await validateOTP()
            if(otpVerify) {
                let ogPayload = {
                    RegType: 'EX',
                    ApptDate: payload?.appointmentDate,
                    FromTime: payload?.appointmentTime,
                    ToTime: payload?.appointmentEndTime,
                    DocCode: payload?.docCode,
                    PatFirstName: form?.Firstname,
                    PatLastName: form?.Lastname,
                    PatMobNo: form?.Mobile,
                    PATISDCD: '+'+form?.MobileCode,
                    SlotType: payload?.appointmentType,
                    PatEmailId: form?.Email,
                    Country_Code: form?.Country
                }
                bookAppointment(ogPayload)
            } else {
                setBtnLoading(false)
                toast.error("Invalid OTP")
            }
        }
    }

    const bookAppointment = (ogPayload) => {
        initHeaders()
        API.bookAnAppoinment(ogPayload)
        .then(res => {
            if (res) {
                let data = res?.Data
                navigate(`/user/register`, { state: { apiData: data, formData: { ...form, otp, ...payload} }})
            }
            closeModal()
            setBtnLoading(false)
        }).catch(err => {
            console.log(err)
            closeModal()
            setBtnLoading(false)
        })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === 'Mobile') { 
            let val = acceptOnlyNumbers(e.target.value)
            setForm({
                ...form,
                [name]: val,
            });
        } else {
            setForm({
                ...form,
                [name]: value,
            });
        } 
    };

    const getCountryData = () => {
        USER_API.getAllCountry()
        .then(res => {
            if(res?.Data) {
                setCountryData(res?.Data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getCountryData()
    },[])

    return (
        <Modal
            show={showModal}
            animation={false}
            centered
            size="lg"
            className="bookAppointmentModal"
            dialogClassName="modal_video modal common_modal"
            // dialogClassName="modal_video common_modal"
        >
            <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <Modal.Body>
                <div class="appointmentWrap">
                    <div class="rightBox">
                        <h3>Book Appointment</h3>
                        <div class="form_wrap">
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <div className={`form-group -animated ${form.Firstname ? '-active' : ''}`}>
                                        <label>First Name <span className="mandatory">*</span></label>
                                        <input onChange={handleInputChange}  name="Firstname" value={form.Firstname} className="form-control" type="text" />
                                        {formValidator.current.message('Firstname', form.Firstname, 'required|alpha')}
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className={`form-group -animated ${form.Lastname ? '-active' : ''}`}>
                                        <label>Last Name <span className="mandatory">*</span></label>
                                        <input onChange={handleInputChange}  name="Lastname" value={form.Lastname} className="form-control" type="text" />
                                        {formValidator.current.message('Lastname', form.Lastname, 'required|alpha')}
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className={`form-group -animated ${form.Email ? '-active' : ''}`}>
                                        <label>Email Address <span className="mandatory">*</span></label>
                                        <input onChange={handleInputChange}  name="Email" value={form.Email} className="form-control" type="text" />
                                        {formValidator.current.message('Email', form.Email, 'required|email')}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="form-group select-dd">
                                        <label>Country <span className="mandatory">*</span></label>
                                        <select onChange={handleInputChange} value={form.Country} name="Country" id="" className="form-control custom-select" placeholder={form.Country ? countryData.find(c => c.country_code === form.Country).country_name : "Select Country"}>
                                            <option hidden>Choose Country</option>
                                            {countryData?.length
                                            ? countryData.map(country => <option key={country?.country_code} value={country?.country_code}>{country?.country_name}</option>) 
                                            : null}
                                        </select>
                                        {formValidator.current.message('Country', form.Country, 'required')}
                                    </div>
                                </div>
                                {/* <MobileVerifyViaOTP
                                    form={form}
                                    setForm={setForm}
                                    otp={otp}
                                    setOTP={setOTP}
                                    verifyOpen={verifyOpen}
                                    mobileValidator={mobileValidator}
                                    otpValidator={otpValidator}
                                    onVerify={onVerify}
                                    otpLoading={otpLoading}
                                    onResendOTP={onResendOTP}
                                /> */}
                                <div className="col-lg-12 col-md-12">
                                    <div className="row otp_row">
                                        <div className="col-md-6">
                                            <div className={`form-group -animated -active`}>
                                                <label>Mobile <span className="mandatory">*</span></label>
                                                <PhoneInput
                                                    className="mt-2"
                                                    preferredCountries={['us','in']}
                                                    country={'in'}
                                                    value={form.MobileExt}
                                                    countryCodeEditable={false}
                                                    disabled={verifyOpen}
                                                    placeholder={''}
                                                    onChange={(value, country, e, formattedValue) => {
                                                        setForm({
                                                            ...form,
                                                            ['MobileCode'] : country?.dialCode,
                                                            ['Mobile'] : value.substring(country?.dialCode?.length)
                                                        })
                                                    }}
                                                    inputProps={{
                                                        name: 'Mobile',
                                                        required: true,
                                                    }}
                                                />
                                                {mobileValidator.current.message('Mobile ', form.Mobile , 'required|validateMobile')}
                                                {!verifyOpen && <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />}
                                            </div>
                                        </div>
                                        {verifyOpen &&
                                            <div className="col-md-4 mb-2">
                                                <div className={`form-group -animated ${otp ? '-active' : ''}`}>
                                                    <label>Enter OTP <span className="mandatory">*</span></label>
                                                    <input className="form-control" type="text" name="OTP" value={otp} maxlength="6" onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))}/>
                                                    {/* <i class="icon success"></i> */}
                                                    {otpValidator.current.message('OTP', otp, 'required|min:6')}
                                                </div>
                                                <LinkButton title={`Resend OTP`} resend onBtnClick={onResendOTP} loading={otpLoading} />
                                            </div>
                                        }
                                    </div>
                                </div>  
                            </div>
                            <div class="btn_wrap">
                                <APIButton 
                                    title={`Submit`} 
                                    loading={btnLoading} 
                                    disabled={btnLoading}
                                    onBtnClick={() => continueRegister()}  
                                    className={`btn btn-primary-solid`} 
                                />
                            </div>
                            <p className="mt-3 mb-0"><small>Please ensure that the User you are registering is not already registered with a different mobile number.</small></p>
                        </div>
                    </div>  
                </div>  
            </Modal.Body>
        </Modal>
    )
}

export default BookAppointmentModal