import React, { useCallback, useEffect, useState, useRef } from "react"
import toast from "react-hot-toast"
import { navigate } from "gatsby"
import { useLocation } from '@reach/router'
import SimpleReactValidator from "simple-react-validator"

import API from "../api/user"
import PAYMENT_API from '../api/payment'
import DOC_API, { initHeaders } from "../api/doctors"
import useScript from "../service/useScript"
import Modal from "react-bootstrap/Modal"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Loader from "../components/addon/Loader"
import PaymentBilling from "../components/addon/paymentBilling/billing"
import BookAppointmentModal from "../components/addon/modal/bookAppointment"
import APIButton from "../components/addon/button/apiButton"

import { formatAppointmentDate, getCookie, format24To12HoursTime, acceptOnlyNumbers, formatOrderDateTime, generateUniqueId, formatPaymentDateTime } from "../service/helper"
import { PAYMENT, BILLDESK_MERCHANT_ID, BILLDESK_SECURITY_ID } from '../service/config'
import UseCustomValidator from "../service/simpleValidator"

const ConfirmPay = (props) => {

        const {} = props
        // useScript("/assets/js/confirmpay.js")
        const location = useLocation()
        const [userInfo, setUserInfo] = useState({})
        const [bookingCharges, setBookingCharges] = useState(null)
        const [checkbox, setCheckbox] = useState(false)
        const [patients, setPatients] = useState([])
        const [selectedPatient, setSelectedPatient] = useState(null)
        const [appointmentType, setAppointmentType] = useState("")
        let [loading, setLoading] = useState(true)
        const [total, setTotal] = useState(null)
        const [loginFlow, setLoginFlow] = useState(false)
        const [showModal, setShowModal] = useState(false)
        const [btnloader, setBtnLoader] = useState(false)
        const [chargesLoader, setChargesLoader] = useState(false)
        const [alternateEmail, setAlternateEmail] = useState("")
        const [alternateContactNo, setAlternateContactNo] = useState("")
        const [payModal, setPayModal] = useState(false)
        const [, forceUpdateForRegister] = useState();
        const { customMobileValidator } = UseCustomValidator()
        const validator = customMobileValidator

        useEffect(() => {
            if(location && window && location?.state && location?.state?.formData && location?.state?.apiData) {
                setLoginFlow(false)
                let formData = location.state.formData
                let apiData = location.state.apiData
                initHeaders()
                setUserInfo({ ...formData, ...apiData})
                setSelectedPatient({ ...formData, ...apiData })
                API.getUserFromMobileNumber(formData?.Mobile || apiData?.mobileno).then(response => {
                    if(response.Data){
                        setPatients(response.Data)
                        setLoading(false)
                    }
                }).catch(err => {
                    console.log(err);
                    toast.error("Something went wrong")
                    setLoading(false)
                })
            }
            if(location && window && location?.state && location?.state?.formData && location?.state?.loginData) {
                setLoginFlow(true)
                let formData = location.state.formData
                let loginData = location.state.loginData
                initHeaders()
                setUserInfo({ ...formData, ...loginData })
                if(loginData?.pinNo) {
                    setPatients([loginData])
                    setSelectedPatient(loginData)
                    setLoading(false)
                } else if (loginData?.flow === 'mobile') {
                    API.getUserFromMobileNumber(formData?.Mobile || loginData?.mobileno)
                    .then(response => {
                        if(response){
                            let user = response.find(el => el?.pinNo === loginData?.hh_number)
                            setSelectedPatient(user)
                            let arr = response.filter(el => el?.regType === 'HS') //response.filter(el => el?.regType === 'HH')
                            setPatients(arr)
                            setLoading(false)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        toast.error("Something went wrong")
                        setLoading(false)
                    })
                } else {
                    API.getUserFromHH(loginData?.hh_number)
                    .then(response => {
                        if(response?.Data){
                            setPatients(response.Data)
                            if(response.Data?.length) {
                                setSelectedPatient(response.Data?.[0])
                            }
                            setLoading(false)
                            let userData = JSON.parse(getCookie("user"));
                            let userFound = response.Data.find(el => el?.pinNo === userData?.hh_number)
                            // setSelectedPatient(userFound)
                        }
                    }).catch(err => {
                        console.log(err);
                        toast.error("Something went wrong")
                        setLoading(false)
                    })
                }
            }
            // if(getCookie("user")) {
            //     let userData = getCookie("user");
            //     setSelectedPatient(JSON.parse(userData))
            // }
            if(!location?.state?.formData) {
                navigate('/')
            }
        },[location])

        useEffect(() => {
            if(selectedPatient){
                setChargesLoader(true)
                DOC_API.getAppoinmentCharges({
                    "RegType": selectedPatient?.regType || "HS",
                    'ApptDate' : userInfo.appointmentDate,
                    'FromTime' : userInfo.appointmentTime,
                    'ToTime' : userInfo.appointmentEndTime,
                    'DocCode' : userInfo.docCode,
                    'SlotType' : userInfo.appointmentType,
                    "PatPIN": selectedPatient?.pinNo,
                    "NationalityCode": selectedPatient?.patCountryCD
                }).then(response => {
                    if(response?.Data){
                        setBookingCharges(response?.Data)
                    }
                    setChargesLoader(false)
                }).catch(err => {
                    console.log(err)
                    setChargesLoader(false)
                    toast.error("Something went wrong")
                })
            }
        }, [selectedPatient])

        useEffect(() => {
            if(window && window.initConfirmPaySlider){
                window.initConfirmPaySlider();
            }
            if (typeof window.initSelect !== 'undefined') window.initSelect();
        },[loading, appointmentType, userInfo, patients])

        useEffect(() => {
            const type = userInfo.appointmentType
            console.log('userInfo.appointmentType======>>>',userInfo.appointmentType)
            switch (type) {
                case "tc":
                    setAppointmentType("Privilege Clinic (TC) :")
                    break;
                case "regular":
                    setAppointmentType("Regular Consultation :")
                    break;
                case "tele":
                    setAppointmentType("Teleconsultation :")
                    break;
                default:
                    break;
            }
        }, [userInfo])

        const handleCheckbox = (e) => {
            setCheckbox(e.target.checked)
        }

        const onConfirmPay = () => {
            const result = validator.current.allValid()
            if (checkbox && selectedPatient && result) {
                openPayModal()        
            } else if (!result) {
                validator.current.showMessages()
                forceUpdateForRegister(1)
            } else {
                if(!selectedPatient){
                    toast.error("Please select the patient.")
                }else{
                    toast.error("Please accept the Terms and conditions before proceeding.")
                }
            }
        }

        const bookDocAppointment = () => {
            setBtnLoader(true)
            if(loginFlow) {
                const payload = {
                    'RegType' : loginFlow ? 'HS' : 'EX',
                    'ApptDate' : userInfo.appointmentDate,
                    'FromTime' : userInfo.appointmentTime,
                    'ToTime' : userInfo.appointmentEndTime,
                    'DocCode' : userInfo.docCode,
                    'SlotType' : userInfo.appointmentType,
                    'PatPIN' : selectedPatient.pinNo || selectedPatient.pin,
                    'Country_Code' : selectedPatient?.patCountryCD,
                    // 'PatFirstName' : selectedPatient.firstname,
                    // 'PatLastName' : selectedPatient.lastname,
                    // 'PatMobNo' : selectedPatient.mobileno,
                    // 'PatEmailId' : selectedPatient.email,
                    // 'Age' : selectedPatient.patage,
                    // 'AgeType' : 'Y',
                    // 'Gender' : selectedPatient.gender
                    //'consult_amount' : userInfo.charges
                }
                DOC_API.bookAnAppoinment(payload)
                .then(res => {
                    let totalCharge = 0
                    if(Object?.keys(res?.Data).length) {
                        for (const [key, value] of Object.entries(res?.Data)) {
                            if(key.includes('_charge')) {
                                totalCharge = totalCharge + Number(value)
                            }
                        }
                    }
                    let appointmentNo = res?.Data?.appt_no
                    navigateToPayment(appointmentNo, totalCharge)
                }).catch(err => {
                    console.log(err);
                    if(loginFlow) {
                        navigate(userInfo?.docPath?.alias)
                    }
                    toast.error("Something went wrong")
                    setBtnLoader(false)
                })
            } else {
                navigateToPayment()
            }  
        }
        const navigateToPayment = async (appointmentNo, totalCharge) => {
            let json = {
                RegType: "HS",
                ApptNo: userInfo?.appt_no ? userInfo?.appt_no : appointmentNo,
                TotalPayment: total ? total : totalCharge,
                AltEmail: alternateEmail,
                AltMobile: alternateContactNo,
                voucherType: 'DCH',
                DoctorId: userInfo?.docCode,
                ...(userInfo.appointmentType === 'TEL') && {teleType:'TE'},
                ...(userInfo.appointmentType === 'TEL') && {slotId: userInfo?.slotId},
                ...(userInfo.appointmentType === 'TEL') && {ApptDate: userInfo?.appointmentDate}
            }

            try {
                let response = await PAYMENT_API.convertJsonDataToId(json)
                if(response) {
                    let uniqueId = response?.ref_no
                    //let str = `${BILLDESK_MERCHANT_ID}|${uniqueId}|NA|${total ? total?.toFixed(2) : totalCharge?.toFixed(2)}|NA|NA|NA|INR|NA|R|hindujahsp|NA|NA|F|${selectedPatient?.pinNo}|${selectedPatient?.fullname || 'NA'}|${formatPaymentDateTime(userInfo?.appointmentDate, userInfo?.appointmentTime)}|${userInfo?.docCode === 'OTH' ? 'NA' : userInfo?.doctorName}|KO6|${bookingCharges?.proc_des || 'NA'}|${appointmentNo}|https://hinduja-cms.indigo-consulting.co.in/api/billdesk/callBackUrl`
                    let payload = {
                            "mercid": BILLDESK_MERCHANT_ID,
                            "orderid": uniqueId,
                            "amount": totalCharge?.toFixed(2),
                            "order_date": formatOrderDateTime(new Date()),
                            "currency": "356",
                            "ru": PAYMENT.CALLBACK,
                            "itemcode": "DIRECT",
                            "additional_info": {
                                "additional_info1": selectedPatient?.pinNo,
                                "additional_info2": selectedPatient?.fullname || 'NA',
                                "additional_info3": formatPaymentDateTime(userInfo?.appointmentDate, userInfo?.appointmentTime),
                                "additional_info4": userInfo?.doctorName,
                                "additional_info5": "web",
                                "additional_info6": bookingCharges?.proc_des,
                                "additional_info7": appointmentNo
                            },
                            "device": {
                                "init_channel": "internet",
                                "ip": "127.0.0.1",
                                "user_agent": "Mozilla/5.0(WindowsNT10.0;WOW64;rv:51.0)Gecko/20100101Firefo\n          x/51.0",
                                "accept_header": "text/html",
                                "browser_tz": "-330",
                                "browser_color_depth": "32",
                                "browser_java_enabled": "false",
                                "browser_screen_height": "601",
                                "browser_screen_width": "657",
                                "browser_language": "en-US",
                                "browser_javascript_enabled": "true"
                            }
                        }

                        
                        PAYMENT_API.createOrder(payload)
                            .then(res => {
                                let obj = {
                                    msg: res,
                                    //callback: PAYMENT.CALLBACK
                                }
                                debugger
                                navigate(`/payment`,{ state: obj })
                            }).catch(err => {
                                console.log(err);
                                toast.error("Something went wrong")
                            })

                    // let str2 = `${BILLDESK_MERCHANT_ID}|HNHONLAPT-${generateUniqueId()}|NA|${total ? total : totalCharge+'.00'}|NA|NA|NA|INR|NA|R|${BILLDESK_SECURITY_ID}|NA|NA|F|${selectedPatient?.email}|${selectedPatient?.mobileno}|${response}|NA|NA|NA|NA|NA`
                    // let obj = {
                    //     msg: str,
                    //     //callback: PAYMENT.CALLBACK
                    // }
                    
                }
                setBtnLoader(false)
            }
            catch(err) {
                toast.error("Something went wrong")
                setBtnLoader(false)
            }
        }

        const onAddUser = () => {
            openModal()
            // navigate(`/user/register?redirect_url=${location.pathname}`)
        }

        const openModal = () => {
            setShowModal(true)
        }

        const closeModal = () => {
            setShowModal(false)
        }

        const openPayModal = () => setPayModal(true)
        const closePayModal = () => setPayModal(false)

        useScript("/assets/js/login-register.js");

        return (
            <Layout>
                <Meta
                    files={
                        {
                            js: [],
                            css: [ 
                                '/assets/css/login.css', 
                                '/assets/css/confirmpay.css', 
                                '/assets/css/accessibility.css',
                                "/assets/css/doctor-profile.css",
                            ]
                        }
                    }
                    tags={[]}
                />
                {loading ?
                    <div className="col-lg-12">
                        <Loader loadingMessage="Loading..." />
                    </div>
                    :
                    <main className="innerpage">
                        <section className="section_bg pt-first-section">
                            <div className="container">
                                <h2 className="section-heading">Confirm And Pay</h2>
                                <div className="row no-gutters">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="white_rounded_box confirm_pay">
                                            <div className="common_section head_section">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="d-flex align-items-center">
                                                            {userInfo.image &&
                                                                <figure className="imageCircle">
                                                                    <img src={userInfo.image} alt="" />
                                                                </figure>
                                                            }
                                                            <div className="doctor_name">
                                                                <span>Doctor's Name</span> <br />
                                                                {userInfo?.doctorName || "--"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="tele_consulting"> 
                                                            <span className="label">
                                                            {appointmentType}
                                                            </span>
                                                            <span className="date"><img src="/assets/images/icons/icon_calander.svg" alt="" /> {formatAppointmentDate(userInfo?.appointmentDate)}</span>
                                                            <span className="time">{format24To12HoursTime(userInfo?.appointmentTime)} - {format24To12HoursTime(userInfo?.appointmentEndTime)} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="common_section appoinment_for">
                                                <h3>Appointment For?
                                                    <button className="add_user_mobbtn" onClick={onAddUser}> + Add user</button>
                                                </h3>
                                                <div className="user-carousel row d-flex-wrap">
                                                    {patients &&
                                                        patients.map((p, index) => {
                                                            return (
                                                                <div className="card-slide mx-2 mb-3 width-100px" key={index}>
                                                                    <input checked={selectedPatient?.pin === p?.pinNo || selectedPatient?.pinNo === p?.pinNo } onChange={ () => setSelectedPatient(p) } type="checkbox" id={'a' + index} name="patient"/>
                                                                    <div className="checkbox_card">
                                                                        <label for={'a' + index}>
                                                                            {p.gender === 'M' ? 
                                                                                <div>
                                                                                    <img src="/assets/images/icons/appointment_user_male.svg" alt="" className="normal" />
                                                                                    <img src="/assets/images/icons/appointment_user_male_active.svg" alt="" className="active" />
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <img src="/assets/images/icons/appointment_user_female.svg" alt="" className="normal" />
                                                                                    <img src="/assets/images/icons/appointment_user_female_active.svg" alt="" className="active" />
                                                                                </div>
                                                                            }
                                                                            {`${p.firstname} ${p.lastname}`} <br />
                                                                            <span>{p.pinNo}</span>
                                                                        </label>
                                                                    </div> 
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {/* {loginFlow && <div className="card-slide mx-2 mb-3 width-100px">
                                                        <input type="button" id="add_user" onClick={onAddUser}/>
                                                        <div className="checkbox_card">
                                                            <label for="add_user">
                                                            <img src="/assets/images/icons/add_user.svg" alt="" />
                                                            + Add User
                                                            </label>
                                                        </div>
                                                    </div>} */}
                                                </div>
                                            </div>
                                            {chargesLoader ?
                                                <div className="col-lg-12">
                                                    <Loader loadingMessage="Fetching charges..." />
                                                </div>
                                                :
                                                <div className="common_section when_to_pay">
                                                    {(selectedPatient && bookingCharges) &&
                                                        <>
                                                            <PaymentBilling setTotal={setTotal} apiData={bookingCharges} formData={userInfo} />
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form_wrap p-0 mt-4">
                                                                        <div className="row">
                                                                            {selectedPatient?.email 
                                                                            ? <div className="col-md-4">
                                                                                <div className="form-group -animated -active select-disabled">
                                                                                    <label>Selected User Email ID</label> 
                                                                                    <input className="form-control" type="text" value={selectedPatient?.email} /> 
                                                                                </div>
                                                                            </div> 
                                                                            : null}
                                                                            {selectedPatient?.mobileno 
                                                                            ? <div className="col-md-4">
                                                                                <div className="form-group -animated -active select-disabled">
                                                                                    <label>Selected User Contact No</label>
                                                                                    <input className="form-control" type="text" disable value={selectedPatient?.mobileno} />
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                        </div> 
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="form-group -animated">
                                                                                    <label>Alternate Email ID</label> 
                                                                                    <input className="form-control" type="text" value={alternateEmail} onChange={(e) => setAlternateEmail(e?.target?.value)} />
                                                                                    {validator.current.message('alternateEmail', alternateEmail, 'email')}  
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group -animated">
                                                                                    <label>Alternate Contact No</label>
                                                                                    <input className="form-control" type="text" value={alternateContactNo} onChange={(e) => setAlternateContactNo(acceptOnlyNumbers(e?.target?.value))} />
                                                                                    {validator.current.message('alternateContactNo', alternateContactNo, 'min:10|max:10|validateMobile')} 
                                                                                </div>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                    <div className="checkbox-group">
                                                                        <div className="custom-checkbox">
                                                                            <input defaultChecked={checkbox} onChange={handleCheckbox} type="checkbox" id="all_tc" />
                                                                            <label for="all_tc">I have read and agree to the <a target="_blank" href="/terms-and-conditions">Terms Of Service</a> and <a target="_blank" href="/legal-terms-conditions">Privacy Policy.</a></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn_wrap">
                                                                        <APIButton 
                                                                            title={`Confirm and Pay`}
                                                                            loading={btnloader}
                                                                            disabled={btnloader}
                                                                            onBtnClick={() => onConfirmPay()}  
                                                                            className={`btn btn-primary`} 
                                                                        />
                                                                        {/* <a href="javascript:void(0)" onClick={onConfirmPay} className="btn btn-primary">Confirm and Pay</a> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {loginFlow && 
                            <BookAppointmentModal 
                                showModal={showModal} 
                                closeModal={closeModal} 
                                userInfo={userInfo}
                            />}
                    </main>
                    
                }
                <Modal
                    show={payModal}
                    animation={false}
                    centered
                    size="lg"
                    dialogClassName="modal common_modal pay_modal modal-center"
                >
                    <button type="button" onClick={closePayModal} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Header>
                        <h2>TERMS AND CONDITIONS</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="pay_terms_wrap">
                            <div>
                                <p>
                                    The On-line payment service is provided in order to
                                    facilitate access to avail and pay the services offered
                                    online. By accepting/ agreeing to these Terms and
                                    Conditions, the User expressly agrees that his/ her use of
                                    the on-line payment service is at their own accord. The
                                    Hospital may update these terms from time to time and any
                                    changes will be effective immediately on being set out
                                    here. Please ensure you are aware of the current terms{" "}
                                </p>
                                <ul>
                                    <li>
                                        This facility is only for Consultation by appointment.
                                    </li>
                                    <li>
                                        Booked appointment is non -transferrable for any other
                                        Consultant or Patient likewise Payment made through
                                        online mode is non-transferrable
                                    </li>
                                    <li>
                                        In case of multiple consultation appointments, separate
                                        transactions will have to be processed.
                                    </li>
                                    <li>
                                        <strong>
                                        You may cancel your appointment 24 hours prior to the
                                        appointment time and receive a refund (except
                                        Registration Charges, where applicable). No refund
                                        will be available after this. Please note that, for
                                        cancellation of appointment on patient request, the
                                        patient needs to send an email to
                                        hindujaclinic@hindujahospital.com{" "}
                                        </strong>
                                        <strong>24hrs before the Appointment time, the </strong>
                                        <strong>
                                        refund will be processed within 7 working days of
                                        receiving such a request.{" "}
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                        In case of cancellation of appointment by patient or
                                        by the hospital a refund will be processed (in
                                        Cheque/NEFT/ On-line mode) in the card holder’s name.
                                        The processing of the refund will take 7 working days.{" "}
                                        </strong>
                                    </li>
                                    <li>
                                        You may choose to collect the cheque refund (when opted)
                                        from the OPD ground floor reception or it will be
                                        couriered to the address provided by you (please inform
                                        your preference)
                                    </li>
                                    <li>
                                        For rescheduling of cancelled appointments, a fresh
                                        appointment is required to be booked. The Appointment
                                        Cell will assist you with the same in such case; if you
                                        wish you pay on-line a new payment transaction will have
                                        to be processed.
                                    </li>
                                    <li>
                                        Please ensure that the Credit/ Debit card details
                                        provided by you for availing the services are correct
                                        and accurate and you shall not use the credit/ debit
                                        card which is not lawfully not owned by you
                                    </li>
                                    <li>
                                        The hospital cannot accept the liability for a payment
                                        not reaching the hospital due to incorrect card details
                                        or personal details provided by you neither can we
                                        accept liability if payment is refused or decline by
                                        Credit/ Debit card supplier for any reason
                                    </li>
                                    <li>
                                        The information provided will not be utilized and shared
                                        by the hospital by the third parties unless required by
                                        law regulations or court orders
                                    </li>
                                    <li>
                                        Server Time-out/ Session Time-out – In case the web-site
                                        or payment service providers web-page is experiencing
                                        any server related issues like “slow down” or “failure”
                                        or “session time-out” the user shall, before initiating
                                        the second payment, check whether his/ her bank account
                                        has been debited or not
                                    </li>
                                    <li>
                                        All the rates are subject to change without prior
                                        intimation
                                    </li>
                                </ul>
                                <p>Telemedicine Consultation - Patient’s Consent</p>
                                <p>
                                    <strong>
                                    (To be read and understood by Patient or Care Giver, as
                                    the case may be, and accepted by the Patient in writing,
                                    by way of Explicit Consent, prior to initiation of a
                                    Telemedicine Consultation)
                                    </strong>
                                </p>
                                <p>
                                    1. I hereby confirm that I have (i) read and understood or
                                    (ii) my Care Giver has read and explained to me the below
                                    mentioned terms and conditions (hereinafter referred to as
                                    the “T&amp;C”), which is required to be read, understood
                                    and accepted by me (in pursuance of the TM Guidelines (as
                                    defined herein below)), prior to initiation of a
                                    Telemedicine consultation.
                                    <br />
                                    <br />
                                    2. Definitions:
                                </p>
                                <ul>
                                    <li>
                                        <em>"Care Giver"</em>&nbsp;means and includes a family
                                        member, or any person authorized by the Patient to
                                        represent the Patient. “Health Worker” means and
                                        includes a nurse, allied health professional, mid-level
                                        health provider, ANM or any other health worker
                                        designated by an appropriate authority, and who
                                        accompanies the Patient.
                                    </li>
                                    <li>
                                        <em>"Hospital"</em>&nbsp;means P.D. Hinduja National
                                        Hospital and Medical Research Centre.
                                    </li>
                                    <li>
                                        <em>"Patient"</em>&nbsp;shall mean the person executing
                                        this form prior to availing of a Telemedicine
                                        consultation.
                                    </li>
                                    <li>
                                        <em>"Registered Medical Practitioner"</em>&nbsp;or "RMP"
                                        means a person who is enrolled in the State Register or
                                        the National Register under the Indian Medical Council
                                        Act 1956, and is a consultant of the Hospital.
                                    </li>
                                    <li>
                                        <em>"Telemedicine"</em>&nbsp;means the delivery of
                                        health-care services, where distance is a critical
                                        factor, by all health-care professionals using
                                        information and communications technologies for the
                                        exchange of valid information for diagnosis, treatment
                                        and prevention of disease and injuries, research and
                                        evaluation, and the continuing education of health-care
                                        workers, with the aim of advancing the health of
                                        individuals and communities.
                                    </li>
                                    <li>
                                        <em>"TM Guidelines"</em>&nbsp;means the “Telemedicine
                                        Practice Guidelines” issued in March 2020 by the Board
                                        of Governors of the Medical Council of India, as may be
                                        amended from time to time.
                                    </li>
                                    <li>
                                        <em>"TM Communication"</em>&nbsp;means and includes all
                                        channels of communication with the Patient that leverage
                                        Information Technology platforms, including voice,
                                        audio, text &amp; digital data exchange, e.g. telephone,
                                        video, devices connected over LAN, WAN, internet, mobile
                                        or landline phones, chat platforms like WhatsApp,
                                        Facebook Messenger etc., or mobile applications or
                                        internet based digital platforms for Telemedicine or
                                        data transmission systems, like skype or email or fax
                                        etc.
                                    </li>
                                </ul>
                                <p>
                                    3. I am above 18 years of age, of sound mind and am
                                    competent to consult an RMP / I am a minor below the age
                                    of 18 years, of sound mind and I am consulting a RMP along
                                    with a consenting adult, who is above 18 years of age and
                                    of sound mind
                                    <br />
                                    4. By accepting these T&amp;C and executing this Patient
                                    consent form, I agree to a medical consultation via a TM
                                    Communication and I hereby confirm that I have the legal
                                    right and ability to accept these T&amp;C
                                    <br />
                                    5. I am residing in India and I undertake that my TM
                                    Communication for Telemedicine will be originating from a
                                    place within India and not from any other place outside
                                    India
                                    <br />
                                    6. I agree and confirm that the Hospital and/or the RMP
                                    will need to verify and confirm my identity (and the
                                    identity of any adult accompanying me, including a Care
                                    Giver or Health Worker) and my and their name, age,
                                    address, email ID, phone number, registered ID or any
                                    other identification as may be deemed appropriate. I
                                    further confirm to provide the Hospital and/or the RMP any
                                    additional proof that they may require, as they deem
                                    appropriate
                                    <br />
                                    7. I undertake that all information which is provided to
                                    the Hospital and/or the RMP will be complete and valid
                                    information and nothing will be mis-stated, withheld or
                                    false .<br />
                                    8. I further understand, agree and confirm that a virtual
                                    consultation via Telemedicine, involving TM Communication,
                                    may necessitate the transmission of video or digital
                                    photographs of me, or electronic transfer of my medical
                                    records, by the Hospital and/or the RMP deem fit (at their
                                    sole discretion) and which I hereby absolutely agree to.
                                    <br />
                                    9. I understand, agree and confirm that the information
                                    required to be furnished by me may vary from one RMP to
                                    another, based on his/her professional experience and
                                    discretion and having regard to different medical
                                    conditions, based on the defined clinical standards and
                                    standard treatment guidelines, and I hereby agree to
                                    furnish whatever information is required in this regard
                                    <br />
                                    10. I understand, agree and confirm that my records,
                                    reports, documents, images, diagnostics, data etc.
                                    (digital or non-digital) utilized in / during the virtual
                                    consultation via Telemedicine will be retained by the RMP
                                    and/or the Hospital and I agree that I have no objection
                                    to the same.
                                    <br />
                                    11. I understand, agree and confirm that virtual
                                    consultation via Telemedicine is limited to first aid,
                                    life-saving measure, counselling and advice on referral
                                    and is NOT for any medical emergencies, life threatening
                                    conditions or for Patients requiring acute care.
                                    <br />
                                    12. I further understand, agree and confirm that if a
                                    physical examination, is critical information for a
                                    consultation, the RMP will not proceed with the virtual
                                    consultation via Telemedicine until I am physically
                                    examined through an in-person consultation.
                                    <br />
                                    13. I understand, agree and confirm that a RMP, after
                                    considering the situation and using his/her best judgment,
                                    is entitled to decide on the best tool for a TM
                                    Communication and I agree to abide by the decision of the
                                    RMP.
                                    <br />
                                    14. I understand, agree and confirm that during the course
                                    of a virtual consultation via Telemedicine, a RMP may seek
                                    the assistance of resident doctors / other physicians/
                                    trained nurses of the Hospital and I hereby agree to the
                                    same.
                                    <br />
                                    15. I understand, agree and confirm that my information
                                    will be shared with other persons working at the Hospital,
                                    in relation to scheduling and billing purposes.
                                    <br />
                                    16. I understand, agree and confirm that a RMP shall have
                                    the final discretion on whether he/she can prescribe or
                                    diagnose by way of a virtual consultation via Telemedicine
                                    (on the basis of the information available via the virtual
                                    consultation) or not. I further agree that the
                                    determination of the RMP is final and I agree to abide by
                                    the same.
                                    <br />
                                    17. I understand, agree and confirm that the diagnosis or
                                    prescription provided by the RMP will be solely based on
                                    the declaration / information given by me (during the
                                    Telemedicine consultation, and in the form of Patient
                                    notes or documents uploaded and provided by me). If I
                                    provide any in-accurate or incorrect or insufficient
                                    information, which leads to a mis-diagnosis by the RMP, I
                                    hereby undertake that the RMP and the Hospital shall not
                                    be held responsible for the same, and I hereby absolutely
                                    release and discharge the RMP and the Hospital against any
                                    and all liabilities and claims.
                                    <br />
                                    18. I understand, agree and confirm that I am responsible
                                    for following the advise / instructions, as regards the
                                    diagnosis provided by the RMP and the treatment as advised
                                    / instructed by the RMP. If I disregard the RMP’s advice /
                                    instruction, in any manner whatsoever, neither the RMP nor
                                    the Hospital shall be liable in any form or manner
                                    whatsoever and they will stand absolutely released and
                                    discharged.
                                    <br />
                                    19. I understand, agree and confirm that it is my sole
                                    responsibility to seek emergency help or follow- up care,
                                    as recommended by the RMP or when in my reasonable
                                    opinion, it is necessary for me to obtain such help or
                                    care.
                                    <br />
                                    20. I understand, agree and confirm that virtual
                                    consultations via Telemedicine, as provided by a RMP will
                                    be on a “as is” and “as available” basis without any
                                    warranty of any kind, expressed or implied, apart from
                                    what is contained in the TM Guidelines, the Indian Medical
                                    Council Act, 1956 and the Indian Medical Council
                                    (Professional Conduct, Etiquettes and Ethics) Regulations
                                    2002, if any.
                                    <br />
                                    21. I understand, agree and confirm that a virtual
                                    consultation via Telemedicine will be considered as a
                                    First Consult if (i) the Patient is consulting with the
                                    RMP for the first time; or (ii) the Patient has consulted
                                    with the RMP earlier, but more than 6 months have lapsed
                                    since the previous consultation; or (iii) the Patient has
                                    consulted with the RMP earlier, but for a different health
                                    condition.
                                    <br />
                                    22. Without prejudice to the above, I further understand,
                                    agree and confirm that a RMP may, in his / her sole
                                    discretion and professional judgment, and having regard to
                                    the interest of the Patient (after taking a holistic view
                                    of the situation) determine that a virtual consultation is
                                    not appropriate for some or all of my medical or clinical
                                    needs and, accordingly, may decide not to provide virtual
                                    consultation services via Telemedicine, and I agree to any
                                    such determination of the RMP.
                                    <br />
                                    23. I understand, agree and confirm that neither the RMP
                                    nor the Hospital nor any of its trustees, consultants,
                                    staff or employees will be held responsible for breach of
                                    confidentiality, if there is reasonable evidence that my
                                    privacy and confidentiality has been compromised as a
                                    result of technology breach or by a person other than a
                                    RMP or the Hospital I further understand, agree and
                                    confirm that although the Hospital and the RMP have taken
                                    reasonable steps to employ industry-standard practices and
                                    technology to ensure the protection of private and
                                    confidential information transmitted through TM
                                    Communication, even the most secure systems can be
                                    compromised and neither the Hospital (nor any of its
                                    trustees, consultants, staff or employees) nor the RMP can
                                    ensure absolute security and I will not hold them liable
                                    for any such breach.
                                    <br />
                                    24. I understand, agree and confirm to the publication of
                                    my treatment for medical, scientific and /or educational
                                    purposes including such photographing, videotaping,
                                    televising with description or other observation, provided
                                    the pictures or descriptive texts accompanying them do not
                                    reveal my identity.
                                    <br />
                                    25. I understand, agree and confirm to make immediate
                                    payments to the Hospital and/or the RMP, as the case may
                                    be, of all amounts due and payable to them pursuant to the
                                    virtual consultation via Telemedicine, provided by the
                                    Hospital and/or the RMP and for all other fees, charges
                                    and taxes that may be charged / leviable in connection
                                    therewith
                                    <br />
                                    26. I understand, agree and confirm that there are
                                    limitations to a virtual consultation via Telemedicine and
                                    that it cannot replace a physical examination and that a
                                    physical examination would be required if the examination
                                    requires physical touch and feel, such as for palpation,
                                    percussion or auscultation.
                                    <br />
                                    27. I understand, agree and confirm that there are
                                    limitation as regards the tools used in TM Communication
                                    for virtual consultation via Telemedicine, which are as
                                    under:
                                </p>
                                <ul>
                                    <li>
                                        <strong>For Video based consultation</strong>&nbsp;– (i)
                                        It is dependent on high quality internet connection at
                                        both ends, else it will lead to a sub optimal exchange
                                        of information; and (ii) there is a possibility of
                                        abuse/ misuse of Patient’s privacy by third parties.
                                    </li>
                                    <li>
                                        <strong>For Audio based consultation</strong>&nbsp;– (i)
                                        Non-verbal cues may be missed; (ii) Not suitable for
                                        conditions that require a visual inspection (e.g. skin,
                                        eye or tongue examination), or physical touch; and (iii)
                                        greater chance of imposters representing the real
                                        Patient.
                                    </li>
                                    <li>
                                        <strong>For Texts based consultation</strong>&nbsp;– (i)
                                        Besides the visual and physical touch, non-verbal cues
                                        may be missed; (ii) difficult to establish rapport with
                                        the Patient; and (iii) cannot be sure of identity of the
                                        RMP or the Patient.
                                    </li>
                                    <li>
                                        <strong>
                                        For Asynchronous (i.e., email, fax, recordings etc.)
                                        based consultation
                                        </strong>
                                        &nbsp;– (i) Not a real time interaction, so just one-way
                                        context is available, relying solely on the articulation
                                        by the Patient; (ii) Patient identification is document
                                        based only and difficult to confirm; (iii) non-verbal
                                        cues may be missed; and (iv) there may be delays because
                                        the RMP may not see the mail immediately.
                                    </li>
                                    <li>
                                        I further agree that if as a result of the limitation of
                                        such tools in TM Communication, there is any
                                        mis-diagnosis, I will not hold the Hospital or the RMP
                                        liable and they will stand absolutely released and
                                        discharged.
                                    </li>
                                </ul>
                                <p>
                                    28. I agree and confirm that if I avail of virtual
                                    consultation via Telemedicine from a RMP or the Hospital,
                                    I undertake to abstain and refrain from doing the
                                    following:
                                    <br />
                                    i. use the virtual consultation via Telemedicine in an
                                    unlawful way or for any illegal and/or unlawful purpose;
                                    <br />
                                    ii. post or transmit (a) a message under a false name, or
                                    (b) any data, materials, content or&nbsp;
                                    <strong>
                                    <em>information</em>
                                    </strong>
                                    &nbsp;(including, without limitation, advice and
                                    recommendations) (collectively, “Information”) which (x)
                                    is libellous, defamatory, obscene, fraudulent, false or
                                    contrary to the ownership or intellectual property rights
                                    of any person, or (y) contains or promotes any virus,
                                    worm, Trojan horse, time bomb or other computer
                                    programming or code that is designed or intended to
                                    damage, destroy or otherwise interrupt or expropriate TM
                                    Communications or facilitate or promote hacking or similar
                                    conduct;
                                    <br />
                                    iii. impersonate or misrepresent my identity (or the
                                    accompanying adult’s identity, as the case may be) or
                                    falsely states or misrepresents my affiliation with a
                                    person or entity,
                                    <br />
                                    iv. tamper, hack or otherwise corrupt the administration,
                                    security, or proper function of the TM Communication;
                                    <br />
                                    v. use robots or scripts with the TM Communication;
                                    <br />
                                    vi. attempt to reverse engineer, reverse assemble, reverse
                                    compile, decompile, disassemble, translate or otherwise
                                    alter, defraud or create false results from any executable
                                    code, Information on or received by TM Communication;
                                    <br />
                                    vii. have anti-virus and/or anti-spyware software running
                                    that is set to override the internet browser's cookie
                                    settings.
                                    <br />
                                    29. I hereby knowingly confirm that:
                                    <br />
                                    (I) THE USE OF THE VIRTUAL CONSULTATION VIA TELEMEDICINE
                                    IS CONDITIONED UPON ACCEPTANCE OF THESE T&amp;C BY MY
                                    AFFIXING MY SIGNATURE TO THIS FORM AND RETURNING THE SAME
                                    TO THE HOSPITAL / RMP OR BY MY CLICKING ‘ACCEPT’,
                                    WHEREUPON THE T&amp;C SETOUT HEREIN WOULD BE BINDING ON
                                    ME.
                                    <br />
                                    (II) BY AGREEING TO A TELEMEDICINE CONSULTATION, I HEREBY
                                    IT IS ACKNOWLEDGED THAT ALL THE T&amp;C HAVE BEEN
                                    REVIEWED, UNDERSTOOD AND ACCEPTED BY ME, INCLUDING ITS
                                    BENEFITS AND RISKS, AND THAT MY CONSENT TO A TELEMEDICINE
                                    CONSULTATION HAS BEEN PROVIDED.
                                    <br />
                                    (III) NEITHER THE HOSPITAL NOR THE RMP, GUARANTEE THAT
                                    TELEMEDICINE CONSULTATION IS THE APPROPRIATE COURSE OF
                                    TREATMENT FOR MY PARTICULAR HEALTH CARE PROBLEM, AND IS
                                    NOT A SATISFACTORY SUBSTITUTE FOR A PHYSICAL CONSULTAITON
                                    / EXAMINATION.
                                    <br />
                                    (IV) THE USE OF THE TELEMEDICINE SERVICES, IS SUBJECT TO
                                    POTENTIAL TECHNOLOGICAL RISKS, INCLUDING INTERRUPTIONS,
                                    UNAUTHORIZED ACCESS AND TECHNICAL DIFFICULTIES, AND I
                                    FURTHER CONFIRM THAT HOSPITAL/ RMP MAY DISCONTINUE THE
                                    TELEMEDICINE SERVICES AT ANYTIME THEY DEEM APPROPRIATE IN
                                    THEIR DISCRETION.
                                    <br />
                                    (V) HOSPITAL’S / RMP’S RESPONSIBILITY WILL END UPON THE
                                    TERMINATION OF A VIRTUAL CONSULTATION VIA TELEMEDICINE.
                                    <br />
                                    (VI) NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                                    HEREIN, I UNCONDITIONALLY AND ABSOLUTELY CONFIRM, AGREE
                                    AND ACCEPT THAT (TO THE MAXIMUM EXTENT PERMITTED BY
                                    APPLICABLE LAW) NEITHER THE RMP NOR THE HOSPITAL, (NOR ITS
                                    TRUSTEES, CONSULTANTS, EMPLOYEES AND STAFF) SHALL BE
                                    LIABLE OR RESPONSIBLE FOR ANY DIRECT, INCIDENTAL, SPECIAL,
                                    CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR INDIRECT DAMAGES
                                    ARISING OUT OF OR OTHERWISE RELATING TO THE PROVISION OF
                                    TELEMEDICINE CONSULTATION TO ME.
                                    <br />
                                    30. (a) All of the T&amp;C as contained herein shall in
                                    all respects be subject to, governed by and construed in
                                    accordance with Indian law including all matters of
                                    construction, validity and performance.
                                    <br />
                                    (b) For the benefit of the RMP, the Hospital and its
                                    Trustees, Consultants, Employees and Staff, the High Court
                                    of Bombay in Mumbai shall have exclusive jurisdiction to
                                    settle any and all disputes which may arise from or are in
                                    connection with these T&amp;C and, accordingly, any legal
                                    action or proceedings arising out of or in connection with
                                    these T&amp;C (“Proceedings”) may be brought in that court
                                    and I irrevocably submit to the jurisdiction of that
                                    court.
                                    <br />
                                    (c) This Clause is for the benefit of RMP, the Hospital
                                    and its Trustees, Consultants, Employees and Staff only.
                                    As a result, and notwithstanding Clause 33(b) hereinabove,
                                    it does not prevent RMP and/or the Hospital from adopting
                                    Proceedings in any other courts with jurisdiction. To the
                                    extent allowed by applicable law, RMP and/or the Hospital
                                    may adopt concurrent proceedings in any number of
                                    jurisdictions.
                                    <br />
                                    31. I understand, agree and confirm (i) that in the event
                                    of any conflict between these T&amp;Cs and the terms
                                    contained in the TM Guidelines, the provisions contained
                                    in the TM Guidelines will prevail and (ii) to the extent
                                    relevant, the terms contained in the TM Guidelines will be
                                    deemed to be incorporated into these T&amp;Cs.
                                    <br />
                                    32. I hereby confirm and acknowledge that I have agreed to
                                    the T&amp;Cs, as contained herein, on my own free will and
                                    volition, after having carefully perused and considered
                                    the same, and having sought independent advice of any
                                    accompanying adult, if required, before consenting to
                                    these T&amp;C. Upon accepting these T&amp;C, I will not
                                    seek to dispute, deny or contest any or all of the terms
                                    as contained herein for any cause or reason whatsoever.
                                </p>
                                <div className="Part">
                                    <p>
                                        <strong> LEGAL TERMS AND CONDITIONS:</strong>
                                        <br />
                                        Welcome to www.khar.hindujahospital.com. All information and
                                        services displayed on the hindujahospital.com website
                                        constitute an “invitation to offer”. Your request for
                                        availment constitutes your offer which shall be subject
                                        to the terms &amp; conditions as listed below. All the
                                        terms &amp; conditions form an Agreement regulating our
                                        relationship with regard to use of online transactions.
                                        <br />
                                        All the terms and conditions must be read very
                                        carefully. If you do not agree to any of the terms and
                                        conditions, you should not use this service. Your use of
                                        a Site shall constitute your acceptance of these terms
                                        and you also agree to be bound by any such
                                        changes/revisions. By using this facility, you accept
                                        that this will form a legal binding agreement between
                                        you and HH.
                                        <br />
                                        The Terms and Conditions contained herein shall apply to
                                        any person (“User”) using the services of HH for making
                                        Consultation fee payments through an online payment
                                        gateway service (“Service”) offered jointly by ICICI
                                        Bank Ltd. and Payment Gateway Service provider, through
                                        HH's website i.e. http://www.khar.hindujahospital.com. Each
                                        User is therefore deemed to have read and accepted these
                                        Terms and Conditions.
                                        <br />
                                        <br />
                                        <strong>Defintions:</strong>
                                        <br />
                                        "Agreement" means the terms and conditions as detailed
                                        herein including all schedules, Appendices, annexures,
                                        Privacy Policy, and will include the references to this
                                        Agreement as amended, supplemented, varied or replaced
                                        from time to time.
                                        <br />
                                        “User"/ "You" means and includes you and/or any person
                                        or an entity including using or accessing the services
                                        provided on this Site.
                                        <br />
                                        "Site" means the online transaction platform owned and
                                        operated by HH which provides a venue to the users of HH
                                        to make online payment for appointment taken o<br />
                                        “Product/s” connotes Admission Forms that appears in our
                                        website i.e.&nbsp;
                                        <a href="http://www.khar.hindujahospital.com/">
                                        www.khar.hindujahospital.com
                                        </a>
                                        &nbsp;where the user/applicant can fill the form online
                                        and services promoted / displayed on the Site and
                                        offered for any use /sale.
                                        <br />
                                        “HH” means Hinduja Hospital.
                                        <br />
                                        <br />
                                        <strong>Eligibility:</strong> <br />
                                        You represent and warrant that you are competent and
                                        eligible to enter into legally binding agreement and
                                        have the requisite authority to bind the other party to
                                        this Agreement. You shall not use this Site if you are
                                        not competent to contract under the applicable laws,
                                        rules and regulations.
                                        <br />
                                        <br />
                                        <strong>Privacy Policy:</strong> <br />
                                        HH respects and protects the privacy of the individuals
                                        that access the information and use the services
                                        provided through them. Individually identifiable
                                        information about the User is not willfully disclosed to
                                        any third party without first receiving the User’s
                                        permission, as covered in this Privacy Policy.
                                        <br />
                                        This Privacy Policy describes HH’s treatment of
                                        personally identifiable information that HH collects
                                        when User is on the HH’s website. HH does collect any
                                        information about the User (such as User’s name, email
                                        address, age, gender etc.). Like any business interested
                                        in offering the highest quality of service to clients,
                                        HH may, from time to time, send email and other
                                        communication to the User tell them about the various
                                        services, features, functionality and content offered by
                                        HH website or seek voluntary information from you.
                                        <br />
                                        Please be aware, however, that HH will release specific
                                        personal information about the User if required to do so
                                        in the following cirumstances:
                                        <br />
                                        a) in order to comply with any valid legal process such
                                        as a search warrant, statute, or court order, or
                                        <br />
                                        b) if any of User’s actions on HH’s website violate the
                                        Terms of Service or any of HH’s guidelines for specific
                                        services, or
                                        <br />
                                        c) to protect or defend HH’s legal rights or property,
                                        the HH site, or HH Users; or
                                        <br />
                                        d) to investigate, prevent, or take action regarding
                                        illegal activities, suspected fraud, situations
                                        involving potential threats to the security, integrity
                                        of HH website/offerings.
                                        <br />
                                        <br />
                                        <strong>
                                        General Terms and Conditions for Online-Payments:
                                        </strong>{" "}
                                        <br />
                                        1. Once a User has accepted these Terms and Conditions,
                                        he/ she may register and avail the Services. A User may
                                        either register on &lt;short form if any else full name
                                        of the client&gt;’s website or alternatively enter his/
                                        her HH number and pay their consultation charges/ fees
                                        in any other manner as may be specified by HH from time
                                        to time.
                                        <br />
                                        2. HH s rights, obligations, undertakings shall be
                                        subject to the laws in force in India, as well as any
                                        directives/ procedures of Government of India, and
                                        nothing contained in these Terms and
                                        <br />
                                        Conditions shall be in derogation of HH's right to
                                        comply with any law enforcement agencies request or
                                        requirements relating to any User’s use of the website
                                        or information provided to or gathered by HH with
                                        respect to such use. Each User accepts and agrees that
                                        the provision of details of his/ her use of the Website
                                        to regulators or police or to any other third party in
                                        order to resolve disputes or complaints which relate to
                                        the Website shall be at the absolute discretion of HH.
                                        <br />
                                        3. If any part of these Terms and Conditions are
                                        determined to be invalid or unenforceable pursuant to
                                        applicable law including, but not limited to, the
                                        warranty disclaimers and liability limitations set forth
                                        herein, then the invalid or unenforceable provision will
                                        be deemed superseded by a valid, enforceable provision
                                        that most closely matches the intent of the original
                                        provision and the remainder of these Terms and
                                        Conditions shall continue in effect.
                                        <br />
                                        4. These Terms and Conditions constitute the entire
                                        agreement between the User and HH. These Terms and
                                        Conditions supersede all prior or contemporaneous
                                        communications and proposals, whether electronic, oral,
                                        or written, between the User and HH. A printed version
                                        of these Terms and Conditions and of any notice given in
                                        electronic form shall be admissible in judicial or
                                        administrative proceedings based upon or relating to
                                        these Terms and Conditions to the same extent and
                                        subject to the same conditions as other business
                                        documents and records originally generated and
                                        maintained in printed form.
                                        <br />
                                        5. The entries in the books of HH and/or the Payment
                                        Service Providers kept in the ordinary course of
                                        business of HH and/or the Payment Service Providers with
                                        regard to transactions covered under these Terms and
                                        Conditions and matters therein appearing shall be
                                        binding on the User and shall be conclusive proof of the
                                        genuineness and accuracy of the transaction.
                                        <br />
                                        6. Refund For Charge Back Transaction: In the event
                                        there is any claim for/ of charge back by the User for
                                        any reason whatsoever, such User shall immediately
                                        approach HH with his/ her claim details and claim refund
                                        from HH alone. Such refund (if any) shall be effected
                                        only by HH via payment gateway or by means of a demand
                                        draft or such other means as HH deems appropriate. No
                                        claims for refund/ charge back shall be made by any User
                                        to the Payment Service Provider(s) and in the event such
                                        claim is made it shall not be entertained.
                                        <br />
                                        7. In these Terms and Conditions, the term “Charge Back”
                                        shall mean, approved and settled credit card or net
                                        banking purchase transaction(s) which are at any time
                                        refused, debited or charged back to merchant account
                                        (and shall also include similar debits to Payment
                                        Service Provider's accounts, if any) by the acquiring
                                        bank or credit card company for any reason whatsoever,
                                        together with the bank fees, penalties and other charges
                                        incidental thereto.
                                        <br />
                                        8. Refund for fraudulent/duplicate transaction(s): The
                                        User shall directly contact HH for any fraudulent
                                        transaction(s) on account of misuse of Card/ Bank
                                        details by a fraudulent individual/party and such issues
                                        shall be suitably addressed by HH alone in line with
                                        their policies and rules.
                                        <br />
                                        9. Server Slow Down/Session Timeout: In case the Website
                                        or Payment Service Provider’s webpage, that is linked to
                                        the Website, is experiencing any server related issues
                                        like ‘slow down’ or ‘failure’ or ‘session timeout’, the
                                        User shall, before initiating the second payment, check
                                        whether his/her Bank Account has been debited or not and
                                        accordingly resort to one of the following options:
                                        <br />
                                        (I) In case the Bank Account appears to be debited,
                                        ensure that he/ she does not make the payment twice and
                                        immediately thereafter contact HH via e-mail or any
                                        other mode of contact as provided by HH to confirm
                                        payment.
                                        <br />
                                        (ii)n case the Bank Account is not debited, the User may
                                        initiate a fresh transaction to make payment.
                                        <br />
                                        However, the User agrees that under no circumstances the
                                        Payment Gateway Service Provider shall be held
                                        responsible for such fraudulent/duplicate transactions
                                        and hence no claims should be raised to Payment Gateway
                                        Service Provider No communication received by the
                                        Payment Service Provider(s) in this regards shall be
                                        entertained by the Payment Service Provider(s).
                                        <br />
                                        <br />
                                        <strong>Limitation of Liability:</strong> <br />
                                        1. HH has made this Service available to the User as a
                                        matter of convenience. HH expressly disclaims any claim
                                        or liability arising out of the provision of this
                                        Service. The User agrees and acknowledges that he/ she
                                        shall be solely responsible for his/ her conduct and
                                        that HH reserves the right to terminate the rights to
                                        use of the Service immediately without giving any prior
                                        notice thereof.
                                        <br />
                                        2. HH and/or the Payment Service Providers shall not be
                                        liable for any inaccuracy, error or delay in, or
                                        omission of (a) any data, information or message, or (b)
                                        the transmission or delivery of any such data,
                                        information or message; or (c) any loss or damage
                                        arising from or occasioned by any such inaccuracy,
                                        error, delay or omission, non-performance or
                                        interruption in any such data, information or message.
                                        Under no circumstances shall the HH and/or the Payment
                                        Service Providers, its employees, directors, and its
                                        third party agents involved in processing, delivering or
                                        managing the Services, be liable for any direct,
                                        indirect, incidental, special or consequential damages,
                                        or any damages whatsoever, including punitive or
                                        exemplary arising out of or in any way connected with
                                        the provision of or any inadequacy or deficiency in the
                                        provision of the Services or resulting from unauthorized
                                        access or alteration of transmissions of data or arising
                                        from suspension or termination of the Services.
                                        <br />
                                        3. HH and the Payment Service Provider(s) assume no
                                        liability whatsoever for any monetary or other damage
                                        suffered by the User on account of:
                                        <br />
                                        (I) the delay, failure, interruption, or corruption of
                                        any data or other information transmitted in connection
                                        with use of the Payment Gateway or Services in
                                        connection thereto; and/ or
                                        <br />
                                        (ii) any interruption or errors in the operation of the
                                        Payment Gateway.
                                        <br />
                                        4. The User shall indemnify and hold harmless the
                                        Payment Service Provider(s) and HH and their respective
                                        officers, directors, agents, and employees, from any
                                        claim or demand, or actions arising out of or in
                                        connection with the utilization of the Services.
                                        <br />
                                        5. The User agrees that HH or any of its employees will
                                        not be held liable by the User for any loss or damages
                                        arising from your use of, or reliance upon the
                                        information contained on the Website, or any failure to
                                        comply with these Terms and Conditions where such
                                        failure is due to circumstance beyond HH’s reasonable
                                        control.
                                        <br />
                                        <br />
                                        <strong>Miscellaneous Conditions:</strong> <br />
                                        1. Any waiver of any rights available to HH under these
                                        Terms and Conditions shall not mean that those rights
                                        are automatically waived.
                                        <br />
                                        2. The User agrees, understands and confirms that his/
                                        her personal data including without limitation details
                                        relating to debit card/ credit card transmitted over the
                                        Internet may be susceptible to misuse, hacking, theft
                                        and/ or fraud and that HH or the Payment Service
                                        Provider(s) have no control over such matters.
                                        <br />
                                        3. Although all reasonable care has been taken towards
                                        guarding against unauthorized use of any information
                                        transmitted by the User, HH does not represent or
                                        guarantee that the use of the Services provided by/
                                        through it will not result in theft and/or unauthorized
                                        use of data over the Internet.
                                        <br />
                                        4. HH, the Payment Service Provider(s) and its
                                        affiliates and associates shall not be liable, at any
                                        time, for any failure of performance, error, omission,
                                        interruption, deletion, defect, delay in operation or
                                        transmission, computer virus, communications line
                                        failure, theft or destruction or unauthorized access to,
                                        alteration of, or use of information contained on the
                                        Website.
                                        <br />
                                        <br />
                                        <strong>
                                        Debit/Credit Card, Bank Account Details:
                                        </strong>{" "}
                                        <br />
                                        1. The User agrees that the debit/credit card details
                                        provided by him/ her for use of the aforesaid Service(s)
                                        must be correct and accurate and that the User shall not
                                        use a debit/ credit card, that is not lawfully owned by
                                        him/ her or the use of which is not authorized by the
                                        lawful owner thereof. The User further agrees and
                                        undertakes to provide correct and valid debit/credit
                                        card details.
                                        <br />
                                        2. The User may pay his/ her consultation fees to HH by
                                        using a debit/credit card or through online banking
                                        account. The User warrants, agrees and confirms that
                                        when he/ she initiates a payment transaction and/or
                                        issues an online payment instruction and provides his/
                                        her card / bank details:
                                        <br />
                                        i. The User is fully and lawfully entitled to use such
                                        credit / debit card, bank account for such transactions;
                                        <br />
                                        ii. The User is responsible to ensure that the card/
                                        bank account details provided by him/ her are accurate;
                                        <br />
                                        iii. The User is authorizing debit of the nominated
                                        card/ bank account for the payment of fees selected by
                                        such User along with the applicable Fees.
                                        <br />
                                        iv. The User is responsible to ensure sufficient credit
                                        is available on the nominated card/ bank account at the
                                        time of making the payment to permit the payment of the
                                        dues payable or the bill(s) selected by the User
                                        inclusive of the applicable Fee.
                                        <br />
                                        <br />
                                        <strong>Personal Information:</strong> <br />
                                        1. The User agrees that, to the extent required or
                                        permitted by law, HH and/ or the Payment Service
                                        Provider(s) may also collect, use and disclose personal
                                        information in connection with security related or law
                                        enforcement investigations or in the course of
                                        cooperating with authorities or complying with legal
                                        requirements.
                                        <br />
                                        2. The User agrees that any communication sent by the
                                        User vide e-mail, shall imply release of information
                                        therein/ therewith to HH. The User agrees to be
                                        contacted via e-mail on such mails initiated by him/
                                        her.
                                        <br />
                                        3. In addition to the information already in the
                                        possession of HH and/ or the Payment Service
                                        Provider(s), HH may have collected similar information
                                        from the User in the past. By entering the Website the
                                        User consents to the terms of HH’s information privacy
                                        policy and to HH’s continued use of previously collected
                                        information. By submitting the User’s personal
                                        information to HH, the User will be treated as having
                                        given his/her permission for the processing of the
                                        User’s personal data as set out herein.
                                        <br />
                                        4. The User acknowledges and agrees that his/ her
                                        information will be managed in accordance with the laws
                                        for the time in force.
                                        <br />
                                        <br />
                                        <strong>Payment Gateway Disclaimer:</strong> <br />
                                        The Service is provided in order to facilitate to pay
                                        Consultation Fees online. HH or the Payment Service
                                        Provider(s) do not make any representation of any kind,
                                        express or implied, as to the operation of the Payment
                                        Gateway other than what is specified in the Website for
                                        this purpose. By accepting/ agreeing to these Terms and
                                        Conditions, the User expressly agrees that his/ her use
                                        of the aforesaid online payment Service is entirely at
                                        own risk and responsibility of the User.
                                        <br />
                                        <br />
                                        <strong>
                                        Modification of Terms and conditions of Service:
                                        </strong>{" "}
                                        <br />
                                        HH may at any time modify the terms and conditions
                                        ("Terms") of the Service without any prior
                                        <br />
                                        notification to you. You can access the latest version
                                        of the Terms at any given time. You should regularly
                                        \review the Terms. In the event wherein the modified
                                        terms are not acceptable to you, you should discontinue
                                        using the service. However, if you continue to use the
                                        service you agree to accept and abide by the modified
                                        Terms. Your use of a Site after any amendment to the
                                        Terms of Use shall constitute your acceptance of these
                                        terms and you also agree to be bound by any such
                                        changes/revisions.
                                        <br />
                                        <br />
                                        <strong>Electronic Communication:</strong> <br />
                                        When you visit HH.ac.in or send e-mails to us or give us
                                        your feedback, you are communicating with us
                                        electronically. You consent to receive communications
                                        from us electronically. We will communicate with you by
                                        e-mail or by posting notices on this website. You agree
                                        that all agreements, notices, disclosures and other
                                        communications that we provide to you electronically
                                        satisfy any legal requirement that such communications
                                        be in writing.
                                        <br />
                                        <br />
                                        <strong>
                                        Revision of Consultation Charges/Changes:
                                        </strong>{" "}
                                        <br />
                                        HH reserves a right to revise the consultation charges
                                        or other charges or withdraw or suspend / cancel, or
                                        discontinue any or all of the services without prior
                                        notice, make modifications and alterations in any or all
                                        of the content, products and services contained on the
                                        site without prior notice and at the sole discretion of
                                        the HH.
                                        <br />
                                        <br />
                                        <strong>Relationship:</strong> <br />
                                        None of the provisions of the User Agreement shall be
                                        deemed to constitute a partnership or agency between you
                                        and HH and you shall have no authority to bind HH in any
                                        manner, whatsoever.
                                        <br />
                                        <br />
                                        <strong>Governing Law and Jurisdiction:</strong> <br />
                                        This agreement and the relationship between you and HH
                                        shall be is governed and construed in
                                        <br />
                                        accordance with the Laws of India without regard to its
                                        conflict of law provisions. You hereby irrevocably
                                        consent to the exclusive jurisdiction and venue of
                                        courts in New Delhi, India, in all disputes arising out
                                        of or relating to the use of the sites/services.
                                        <br />
                                        <br />
                                        <strong>Settlement of Disputes:</strong> <br />
                                        Without prejudice to any provisions in the terms and
                                        conditions herein, any dispute or difference arising out
                                        of or touching this agreement shall be settled amicably
                                        in the First instance. Unresolved disputes or
                                        differences shall be referred to a Sole Arbitrator to be
                                        appointed/nominated by HH. The arbitration shall be
                                        governed by the (Indian) Arbitration and Conciliation
                                        Act, 1996 and any modifications thereon and rules made
                                        thereunder and the law governing the same shall be
                                        Indian Laws. The Seat of arbitration shall be at or at
                                        the sole discretion of the HH.
                                        <br />
                                        <br />
                                        <strong>Force Majeure:</strong>
                                        <br />
                                        HH shall have no liability to you for any interruption
                                        or delay in access to the Site AND service
                                        <br />
                                        Irrespective of the nature of the cause.
                                        <br />
                                        <br />
                                        <strong>Waiver and Severability:</strong> <br />
                                        The failure of HH to exercise or enforce any right or
                                        provision of the Agreement shall not constitute a waiver
                                        of such right or provision. If any provision of the
                                        Agreement is found by a court of competent jurisdiction
                                        to be invalid, the parties nevertheless agree that the
                                        court should endeavor to give effect to the parties'
                                        intentions as reflected in the provision, and the other
                                        provisions of the Agreement remain in
                                        <br />
                                        full force and effect.
                                        <br />
                                        <br />
                                        <strong>Indemnity:</strong> <br />
                                        You shall indemnify and hold harmless HH and its
                                        respective officers, directors, agents, and employees,
                                        from any claim or demand, or actions including
                                        reasonable attorneys' fees, made by any third party or
                                        penalty imposed due to or arising out of your breach of
                                        these terms and conditions.
                                        <br />
                                        <br />
                                        <strong>Acceptance of Privacy Policy:</strong> <br />
                                        By using HH’s sites and services, you signify your
                                        acceptance of this Privacy Statement. If you do not
                                        agree or are not comfortable with any policy described
                                        in this Privacy statement, your only remedy is to
                                        discontinue use of HH sites. We reserve the right, to
                                        modify this Privacy Statement at any time.
                                        <br />
                                        <br />
                                        <strong>Entire Agreement:</strong> <br />
                                        These Terms of Service constitute the entire agreement
                                        between the parties with respect to the subject matter
                                        hereof and supersedes and replaces all prior or
                                        contemporaneous understandings or agreements, written or
                                        oral, regarding such subject matter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => bookDocAppointment()} >Accept & Pay</button>
                    </Modal.Footer>
                </Modal>
            </Layout>
        )
}

export default ConfirmPay